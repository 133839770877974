<template>
  <div>
    <a-row type="flex" style="flex-wrap: nowrap">
      <a-col flex="300px" :span="6">
        <a-button
            type="link"
            icon="folder-add"
            @click="createGroup"
            block
            style="font-size: 18px; height: auto; padding: 16px; text-align: left"
        >新建分组
        </a-button
        >
        <favorite-groups
            ref="favoriteGroups"
            :type="2"
            @select="onSelect"
            style="max-height: 760px; overflow: auto"
        ></favorite-groups>
      </a-col>
      <a-col :span="18">
        <!--           :scrollSetting="{ x: 1200, y: 600 }" -->
        <cn-table
            :configPayload="configAweme"
            :selectValue="searchForm"
            ref="cntable"
            :initData="false"
        >
          <div class="aweme-info" slot="awemeInfo" slot-scope="text">
            <div class="aweme-info__cover">
              <BaseCover :src="text.awemeCover" :width="104" :height="138"/>
            </div>
            <div class="aweme-info__content">
              <div>{{ text.awemeTitle || "-" }}</div>
              <div>播主昵称：{{ text.nickname || "-" }}</div>
              <div>
                平台：{{ text.platformType == 1 ? "抖音" : "快手" }}
              </div>
              <div>媒体号：{{ text.code}}</div>
              <div>发布时间：{{ text.publishTime || "-" }}</div>
            </div>
          </div>

          <div class="detail-item" slot="playVolume" slot-scope="text">
            <span>{{ text.playVolume | humanReadable }}</span>
          </div>
          <div class="detail-item" slot="diggCount" slot-scope="text">
            <span>{{ text.diggCount | humanReadable }}</span>
          </div>
          <div class="detail-item" slot="commentCount" slot-scope="text">
            <span>{{ text.commentCount | humanReadable }}</span>
          </div>
          <div class="detail-item" slot="shareCount" slot-scope="text">
            <span>{{ text.shareCount | humanReadable }}</span>
          </div>

          <div slot="operate" slot-scope="text">
            <a-button
                type="link"
                @click="handleOperate(text.id)"
                :loading="activeId == text.id && loadingBtn"
            >取消收藏
            </a-button
            >

            <a-divider type="vertical"/>
            <router-link
                :to="{
                name:
                  text.platformType === 1
                    ? 'awemeAnalysis'
                    : 'awemeAnalysisKS',
                params: { awemeId: text.bizId },
              }"
                target="_blank"
            >
              视频分析
            </router-link>
            <!-- <a @click="handleOperate('awemeAnalysis', record)">视频分析</a> -->
          </div>
        </cn-table>
      </a-col>
    </a-row>
  </div>
</template>
<style scoped>
.ant-table .ant-list-item {
  padding: 0;
}
</style>
<script>
import AwemeCard from "@/components/widgets/aweme-card";
import FavoriteGroups from "@/components/widgets/favorite-groups";
import CnTable from "@/components/CnTable";
import _http from "@/api/personalcenter";
import BaseCover from "@/components/BaseCover";

let defaultQuery = {
  orderBys: "ctime|desc,awemeId|desc",
  param: {
    groupId: "",
  },
};
export default {
  components: {AwemeCard, FavoriteGroups, CnTable, BaseCover},
  data() {
    return {
      searchForm: {
        groupId: "",
      },
      configAweme: {
        columns: [
          {
            title: "视频内容",
            key: "awemeInfo",
            width: 400,
            scopedSlots: {customRender: "awemeInfo"},
          },
          {
            title: "播放量",
            key: "playVolume",
            scopedSlots: {customRender: "playVolume"},
          },
          {
            title: "点赞量",
            key: "diggCount",
            scopedSlots: {customRender: "diggCount"},
          },
          {
            title: "评论量",
            key: "commentCount",
            scopedSlots: {customRender: "commentCount"},
          },
          {
            title: "转发量",
            key: "shareCount",
            scopedSlots: {customRender: "shareCount"},
          },
          {
            title: "操作",
            key: "operate",
            width: 165,
            fixed: "right",
            scopedSlots: {customRender: "operate"},
          },
        ],
        http_api: _http.getAwemeList,
      },
      activeId: "",
      loadingBtn: false,
    };
  },

  methods: {
    createGroup() {
      this.$refs.favoriteGroups.createGroup();
    },
    onSelect(group = {}) {
      // console.log("group", group);
      this.searchForm = {
        groupId: group.id,
      };
      this.$nextTick(() => {
        this.$refs.cntable.getTableList(1, 10);
      });
    },
    onGroupSaved() {
      this.$refs.favoriteGroups.load();
    },
    init(options = {}) {
      this.$refs.favoriteGroups.load();
    },
    handleOperate(id) {
      this.activeId = id;
      this.loadingBtn = true;
      _http
          .cancelAweme(id)
          .then((res) => {
            if(res.code) {
              this.$refs.cntable.getTableList(1, 10);
            } else {
              this.$message.error(res.message);
            }

          })
          .finall(() => {
            this.loadingBtn = false;
          });
    },
  },
  created() {
  },
  mounted() {
    this.$on("init", this.init);
  },
};
</script>
<style lang="scss" scoped>
.aweme-info {
  display: flex;
  align-items: flex-start;

  &__cover {
    margin: 0 8px 0 0;
  }

  &__content {
    flex: 1;
  }
}
</style>
